
import CustomPageHeader from '@/components/Layout/CustomPageHeader.vue';
import { useStore } from 'vuex';
import { computed } from 'vue';

export default {
  name: 'ViewerNoDashboard',

  components: {
    CustomPageHeader,
  },

  setup() {
    const store = useStore();
    const username = computed(() => store.getters['account/userName']);

    return {
      username,
    };
  },
};
